import React from "react";
import "./langbutton.css";
import { OptionContext } from "../../App";
import { localizedString } from "../../localizedstrings";

export class LangButton extends React.Component {
    render(): JSX.Element {
        return (
            <OptionContext.Consumer>
                {({ lang, toggleLang }) => (
                    <div className="btn-langtoggle-wrapper">
                        <button className="btn-option" type="button" onClick={toggleLang}>
                            {localizedString.languageChange}: {lang}
                        </button>
                    </div>
                )}
            </OptionContext.Consumer>
        );
    }
}
