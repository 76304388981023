import React from "react";
import "./mainmenu.css";
import { GameService } from "../services/game.service";
import { localizedString } from "../localizedstrings";
import { GameListing } from "./gamelisting";

interface MainMenuProps {
    gameService: GameService;
}

interface MainMenuState {
    gameGuid: string;
    playerName: string;
}

export class MainMenu extends React.Component<MainMenuProps, MainMenuState> {

    constructor(props: MainMenuProps) {
        super(props);

        this.state = {
            gameGuid: "",
            playerName: "",
        }

        this.updateGameGuid = this.updateGameGuid.bind(this);
        this.joinGame = this.joinGame.bind(this);
        this.updatePlayerName = this.updatePlayerName.bind(this);
    }

    componentDidMount(): void {
        const gameGuid = this.getUrlParameter("gameId");
        if (gameGuid) {
            this.props.gameService.joinGame(gameGuid, this.state.playerName)
        }
    }

    setName = (): void => {
        this.props.gameService.setName(this.state.playerName);
    };

    updatePlayerName(event: any) {
        this.setState({ playerName: event.target.value })
    }

    getUrlParameter(name: string): string {
        const urlString = window.location.href; //window.location.href
        const url = new URL(urlString);
        const param = url.searchParams.get(name);

        return param ? param : "";
    }

    createGame = (): void => {
        this.props.gameService.createGame(this.state.playerName)
    };

    joinGame = (): void => {
        this.props.gameService.joinGame(this.state.gameGuid, this.state.playerName);
    };

    updateGameGuid(event: any) {
        this.setState({ gameGuid: event.target.value });
    }

    render() {
        return (
            <div className="overlay">
                <div className="mainmenu-container">
                    <div>
                        <img className="pepe-wizard" src="https://wozard-assets.vereto.net/pepe-wizard.gif" alt="pepe-wizard"></img>
                        <h2>{localizedString.welcome}</h2>
                        <p>{localizedString.welcomeLogLine}</p>
                        <div className="mainmenu-inner">
                            <input placeholder={localizedString.playerNamePlaceHolder} onChange={(this.updatePlayerName)} />
                            <button className="btn-create-game btn-mainmenu" type="button" onClick={this.createGame}>
                                {localizedString.createGame}
                            </button>
                        </div>
                        <div className="mainmenu-inner">
                            <input placeholder={localizedString.gameIdPlaceHolder} onChange={(this.updateGameGuid)} />
                            <button className="btn-join-game btn-mainmenu" type="button" onClick={this.joinGame}>
                                {localizedString.joinGame}
                            </button>
                        </div>
                        <div className="mainmenu-inner">
                            <GameListing gameService={this.props.gameService}></GameListing> 
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
