import React from "react";
import "./avatar.css";

interface AvatarProps {
    slot: number;
}

// Display a players avatar
export class Avatar extends React.Component<AvatarProps> {

    determineAvatar(slot: number): string {
        if (slot <= 0) {
            return "https://wozard-assets.vereto.net/avatar/avatar_placeholder.png"
        } else {
            return `https://wozard-assets.vereto.net/wizards/wizard${slot}.png`
        }
        
    }

    render(): JSX.Element {
        return (
            <div className="player-avatar-container">
                <img src={this.determineAvatar(this.props.slot)} alt="player avatar" className="player-avatar" />
            </div>
        );
    };
}