import React from "react";
import "./App.css";
import { ClientGame } from "./components/game";

import { localizedString } from "./localizedstrings";

export const OptionContext = React.createContext({
    lang: "de",
    toggleLang: () => {},
    menuState: "closed",
    toggleMenu: () => {},
});

interface AppProps {}
interface AppState {
    lang: string;
    toggleLang: any;
    menuState: string;
    toggleMenu: any;
}

class App extends React.Component<AppProps, AppState> {
    constructor(props: any) {
        super(props);

        this.state = {
            lang: "de",
            toggleLang: this.toggleLang,
            menuState: "closed",
            toggleMenu: this.toggleMenu
        };
    }

    toggleLang = () => {
        this.setState(state => ({
            lang: state.lang === "en" ? "de" : "en",
        }));
        localizedString.setLanguage(this.state.lang);
    };

    toggleMenu = () => {
        this.setState(state => ({
           menuState: state.menuState === "closed" ? "extended" : "closed"
        }));
    }

    render() {
        return (
            <OptionContext.Provider value={this.state}>
                <div className="App">
                    <div className="container">
                        <ClientGame></ClientGame>
                    </div>
                </div>
            </OptionContext.Provider>
        );
    }
}

export default App;
