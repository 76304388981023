import React from "react";
import "./chatmessage.css";
import { GameChat } from "common";

export class ChatMessage extends React.Component<GameChat> {

    player = "Someone";
    content = "something!";
    timeStamp = new Date();

    render(): JSX.Element {
        return (
            <div className="chat-message-wrapper">
                <div className="chat-player">{this.props.player}:</div> 
                <div className="player-message">{this.props.content}</div>
                <br></br>
                <div className="message-date">{this.props.timeStamp}</div>
            </div>
        );
    };
}