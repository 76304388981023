import React from "react";
import "./hand.css";
import { ClientCard } from "./card";
import { CardSuit, CardValue, PlayerHand, Card } from "common";
import { GameService } from "../services/game.service";

interface HandProps {
    gameService: GameService;
    hand: PlayerHand;
    leadingCardSuit?: CardSuit;
}

export class Hand extends React.Component<HandProps> {

    onPlayerCardClicked = (id: number): void => {
        // Get the card played by the player
        const playedCard: Card[] = this.props.hand.cards.filter((card: Card) => {
            return card.id === id;
        });

        if (playedCard.length > 0) {
            this.props.gameService.submitCard(playedCard[0]);
        }
    };

    hasLeadingCardInHand(): boolean {
        return this.props.hand.cards.some(card => card.suit === this.props.leadingCardSuit);
    }

    render() {
        return (
            <div className="hand-container">
                <div className="hand-inner"> 
                    {this.props.hand.cards.map((card, i) => {
                        if (this.props.leadingCardSuit === card.suit || !this.props.leadingCardSuit || !this.hasLeadingCardInHand() || card.suit === CardSuit.WHITE) {
                            return <div className="hand-card playable"><ClientCard id={card.id} value={card.value as CardValue} suit={card.suit as CardSuit} onCardClick={this.onPlayerCardClicked}></ClientCard></div>
                        } else {
                            return <div className="hand-card unplayable"><ClientCard id={card.id} value={card.value as CardValue} suit={card.suit as CardSuit}></ClientCard></div>
                        }
                    })}
                </div>
            </div>
        );
    };
}