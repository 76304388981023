import React from "react";
import "./optionstogglebutton.css";
import { OptionContext } from "../../App";

export class OptionsToggle extends React.Component {
    render(): JSX.Element {
        return (
            <OptionContext.Consumer>
                {({menuState, toggleMenu}) => (
                    <div onClick={toggleMenu} className={"burger " + menuState} >
                        <div className="bar1"></div>
                        <div className="bar2"></div>
                        <div className="bar3"></div>
                    </div>
                )}
            </OptionContext.Consumer>
        );
    }
}
