import React from "react";
import "./card.css";
import { Card } from "common";

interface CardProps extends Card {
    onCardClick?: (id: number) => void;
}

export class ClientCard extends React.Component<CardProps> {

    onCardClicked = (): void => {
        if (this.props.onCardClick) {
            this.props.onCardClick(this.props.id);
        }
    };

    render() {
        return (
            <img onClick={this.onCardClicked} src={`https://wozard-assets.vereto.net/cards/front/card-${this.props.suit.toLowerCase()}-${this.props.value}.png`} alt="card" className="card" />
        );
    }
}
