import React from "react";
import "./playerdetails.css";
import { Avatar } from "./avatar";
import { Player, Game, GameState, PlayerState } from "common";
import { GameService } from "../services/game.service";
import { localizedString } from "../localizedstrings";
import { ReadyButton } from "./readyButton/readyButton";
import { WozContext } from "./game";

interface PlayerDetailsProps extends Player {
    self: Boolean;
    gameService: GameService;
}

interface PlayerDetailsState {
    playerName: string;
}

export class PlayerDetails extends React.Component<PlayerDetailsProps, PlayerDetailsState> {
    inputTimer: any;

    constructor(props: PlayerDetailsProps) {
        super(props);
        this.state = {
            playerName: "",
        }

        this.setName = this.setName.bind(this);
    }

    componentWillReceiveProps(nextProps: any) { 
        if (nextProps.name !== this.state.playerName) {
            this.setState({playerName: nextProps.name});
        }
    }

    setName = (event: any): void => {
        var duration = 2000;
        this.setState({ playerName: event.target.value });
        clearTimeout(this.inputTimer);
        this.inputTimer = setTimeout(()=>{
            if (this.state.playerName !== "") {
                this.props.gameService.setName(this.state.playerName);
            }
            
        }, duration);
    }

    playerName() {
        if (this.props.self) {
            return <input type="text" className="player-name-input" value={this.state.playerName} onChange={evt => this.setName(evt)} />
        } else {
            return <span>{this.props.name}</span>
        }
    }

    calculateTricksWon(guid: string, game: Game) {
        let tricksWon = 0
        game.rounds[game.rounds.length - 1].turns.forEach(turn => {
            if (turn.trickWinner) {
                if (turn.trickWinner.guid === guid) {
                    tricksWon = tricksWon + 1;
                }
            }
        });

        return tricksWon;
    }

    selfStyle(): string {
        if (this.props.self) {
            return "playerself"
        } else {
            return ""
        }
    }

    render(): JSX.Element {
        return (
            <WozContext.Consumer>
                {({game, currentPlayer}) => (
                    <div className="player-details">
                        <Avatar slot={this.props.slot}></Avatar>
                        <div className="player-details-inner">
                            <div className={"player-details-list " + this.selfStyle()}>
                                <div>
                                    <b>{this.playerName()}</b>
                                </div>
                                <div>
                                    {localizedString.getString(this.props.state)}
                                </div>
                                {game.state !== GameState.Open &&  
                                    <div>
                                        {localizedString.tricksWon}: &nbsp;
                                        {game.rounds.length > 0 && 
                                            this.calculateTricksWon(this.props.guid, game)
                                        }
                                    </div>
                                }
                                {game.state !== GameState.Open && game.rounds[game.rounds.length -1].bids[this.props.guid] !== undefined &&
                                    <div>
                                        {localizedString.currentBid}: &nbsp;
                                        {game.rounds.length > 0 &&
                                            game.rounds[game.rounds.length -1].bids[this.props.guid] 
                                        }
                                    </div>                                        
                                }
                            </div>
                        </div>
                        {((currentPlayer.state === PlayerState.NotReady || game.state === GameState.Open) && this.props.self ) &&
                                <ReadyButton onReadyClick={() => this.props.gameService.togglePlayerReady()} readyState={currentPlayer.state} gameState={game.state}></ReadyButton>
                        }
                    </div>
                )}
            </WozContext.Consumer>
        );
    };
}