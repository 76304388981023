import React, { Fragment } from "react";
import { WozContext } from "../game";
import { localizedString } from "../../localizedstrings";
import "./scoreboard.css";
import { Round } from "common";

interface ScoreboardProps {
    renderCloseBtn: boolean;
    onCloseBtnClick(): void;
}

const ScoreCell = ({
    score,
    bid,
    tricks,
}: {
    score: number | string;
    bid: number | string;
    tricks: number | string;
}) => (
    <div className="player-turn">
        <div className="score-cell player-score">{score}</div>
        <div className="score-cell player-bid">{bid}</div>
        <div className="score-cell player-tricks">{tricks}</div>
    </div>
);

export class Scoreboard extends React.Component<ScoreboardProps> {
    scoreEnd: HTMLDivElement | null | undefined;

    printScores(rounds: Round[], guid: string): JSX.Element[] {
        return rounds
            .map(round => {
                const tricks = round.turns.filter(t => t.trickWinner?.guid === guid).length;
                return <ScoreCell score={round.score[guid]} bid={round.bids[guid]} tricks={tricks} />;
            });
    }

    scrollToBottom = () => {
        this.scoreEnd?.scrollIntoView({behavior: "smooth"});
    }

    render(): JSX.Element {
        return (
            <WozContext.Consumer>
                {({ game: { connectedPlayers, rounds } }) => (
                    <Fragment>
                        <div className="scoreboard">
                            <div>
                                <h3>Scores</h3>
                                <div className="legend">
                                    <div className="legend-line">
                                        <div className="legend-box legend-score"></div> {localizedString.score}
                                    </div>
                                    <div className="legend-line">
                                        <div className="legend-box legend-bid"></div> {localizedString.guess}
                                    </div>
                                    <div className="legend-line">
                                        <div className="legend-box legend-trick"></div> {localizedString.tricksWon}
                                    </div>
                                </div>
                                {this.props.renderCloseBtn &&
                                    <button className="scoreBoardButton" onClick={this.props.onCloseBtnClick}>
                                        X
                                    </button>
                                }
                            </div>

                            <div id="scores" className="scores">
                                    <div className="scores-roundcounter">
                                        {rounds.map((round) => {
                                            return <div className="scores-roundcounter-cell">{round.number}</div>
                                        })}
                                    </div>
                                    {connectedPlayers.map((player) => {
                                        return (
                                            <div className="player-scores" key={player.guid}>
                                                <div className="player-score-header">{player.name}</div>
                                                {this.printScores(rounds, player.guid)}
                                                <div style={{ position: "absolute", bottom: "0px"}}
                                                    ref={(el) => {this.scoreEnd = el; }}></div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </Fragment>
                )}
            </WozContext.Consumer>
        );
    }
}
