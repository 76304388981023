import React from "react";
import "./chatbox.css";
import { ChatMessage } from "./chatmessage";
import { ChatInput } from "./chatinput";
import { GameService } from "../services/game.service";
import { GameChat } from "common";
import ScrollableFeed from 'react-scrollable-feed'

interface ChatProps {
    gameService: GameService;
}

interface ChatState {
    chatMessages: GameChat[];
    chatExtClass: string;
}

export class ChatBox extends React.Component<ChatProps, ChatState> {
    
    constructor(props: ChatProps) {
        super(props);

        this.state = {
            chatMessages: [],
            chatExtClass: ""
        }
        this.toggleChat = this.toggleChat.bind(this);
    }
    
    componentDidMount(): void {
        this.props.gameService.getMessages((msg: GameChat): void => {
            this.state.chatMessages.push(msg);
            this.setState({ chatMessages: this.state.chatMessages });
        })
    }

    toggleChat(): void {
        if (this.state.chatExtClass === "chat-unfolded") {
            this.setState({chatExtClass: ""})
        } else {
            this.setState({chatExtClass: "chat-unfolded"})
        }
    }

    render(): JSX.Element {
        return (
            <div>
                <div className="chaticon" onClick={this.toggleChat}>
                    <img src="https://wozard-assets.vereto.net/icons/chatico.png" alt="chat icon"/>
                </div>
                <div className={`chat-ext-wrapper ${this.state.chatExtClass}`}>
                    <div className="chat-container">
                        <ScrollableFeed>
                            {this.state.chatMessages.map((message: GameChat, index: number) => (
                                <ChatMessage player={message.player} content={message.content} timeStamp={message.timeStamp}/>
                            ))}
                        </ScrollableFeed>
                    </div>
                    <ChatInput gameService={this.props.gameService}></ChatInput>
                </div>
            </div>
        );
    };
}