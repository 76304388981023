import React from "react";
import "./pile.css";
import { ClientCard } from "./card";
import { Game, Round, Card } from "common";
import { WozContext } from "./game";

interface PileProps {
    game: Game;
}

interface PileState {
    displayCards: DisplayCard[];
}

// Pile of played cards in a playphase
export class Pile extends React.Component<PileProps, PileState> {

    constructor(props: PileProps) {
        super(props);

        this.state = {
            displayCards: []
        }
    }

    getDisplayCards(game: Game): DisplayCard[] {
        const recentPile = this.getRecentPile(game.rounds);
        if (Object.keys(recentPile).length !== 0) {
            Object.keys(recentPile).forEach((playedCard) => {
                const displayCard: DisplayCard = {
                    id: Object(recentPile)[playedCard].id,
                    value: Object(recentPile)[playedCard].value,
                    suit: Object(recentPile)[playedCard].suit,
                    posX: this.getRandomPos(),
                    posY: this.getRandomPos(),
                    rotation: this.getRandomRotation()
                }
                if (!this.cardInDisplayAlready(displayCard)) {
                    this.state.displayCards.push(displayCard);
                }
            });
        } else {
            if (this.state.displayCards.length !== 0) {
                this.setState({ displayCards: [] });
            }
        }

        return this.state.displayCards;
    }

    cardInDisplayAlready(displayCard: DisplayCard): boolean {
        let containsCard = false
        this.state.displayCards.forEach((card) => {
            if (card.id === displayCard.id) {
                containsCard = true
            }
        });

        return containsCard
    }

    getRecentPile(rounds: Round[]): { [playerGuid: string]: Card } {
        let recentPile = {};
        if (rounds.length > 0) {
            const recentRound = rounds[rounds.length - 1]
            if (recentRound.turns.length > 0) {
                const recentTurn = recentRound.turns[recentRound.turns.length - 1]
                recentPile = recentTurn.plays;
            }
        }
        return recentPile;
    }

    getRandomRotation() {
        let rotationOffset = 120;
        return Math.floor(Math.random() * (rotationOffset - (-rotationOffset) + 1)) + (-rotationOffset)
    }

    getRandomPos() {
        let posOffsetMax = 150;
        let posOffsetMin = 25;
        return Math.floor(Math.random() * (posOffsetMax - posOffsetMin + 1)) + posOffsetMin
    }

    applyOffset(posX: number, posY: number, rotation: number) {
        return {
            top: posY,
            left: posX,
            transform: `rotate(${rotation}deg)`
        }
    }

    render(): JSX.Element {
        return (
            <WozContext.Consumer>
                {({ game }) => (
                    <div className="pile-container">
                        <div className="pile">
                            {this.getDisplayCards(game).map((card): JSX.Element => {
                                return <span className="pile-card" style={this.applyOffset(card.posX, card.posY, card.rotation)}><ClientCard id={card.id} value={card.value} suit={card.suit} /></span>
                            })}
                        </div>
                    </div>
                )}
            </WozContext.Consumer>
        );
    };
}

interface DisplayCard extends Card {
    posY: number,
    posX: number,
    rotation: number
}