import React from "react";
import "./optionsmenu.css";
import { LangButton } from "../optionsButtons/langbutton";
import { OptionContext } from "../../App";
import { localizedString } from "../../localizedstrings";
import { GameService } from "../../services/game.service";
import { DisconnectButton } from "../optionsButtons/disconnectbutton";

interface OptionsMenuProps {
    gameService: GameService;
    connectedPlayersNum: number;
}

export class OptionsMenu extends React.Component<OptionsMenuProps> {
    render(): JSX.Element {
        return (
            <OptionContext.Consumer>
            {({menuState}) => (
                <div className={"optionsmenu " + menuState}>
                    <h2>{localizedString.optionsMenuTitle}</h2>
                    <LangButton/>
                    {this.props.connectedPlayersNum !== 0 && 
                        <DisconnectButton gameService={this.props.gameService}/>
                    }
                </div>
            )}
            </OptionContext.Consumer>
        );
    }
}