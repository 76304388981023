import React from "react";
import "./styles/readybutton.css";
import { localizedString } from "../../localizedstrings";
import { GameState, PlayerState } from "common";

interface ReadyButtonProps {
    onReadyClick(): void;
    readyState: PlayerState;
    gameState: GameState;
}

interface ReadyButtonState {
    timeRemaining: number;
}

export class ReadyButton extends React.Component<ReadyButtonProps, ReadyButtonState> {
    autoReadyTimer: any;
    playTurnTimeoutSeconds = 3;
    roundTimeoutSeconds = 10;

    constructor(props: ReadyButtonProps) {
        super(props);

        this.state = {
            timeRemaining: this.setAutoReadyTimeout(this.props.readyState, this.props.gameState),
        }
        this.autoReadyTimer = 0;
        this.countDown = this.countDown.bind(this);
        this.onReadyClickHandler = this.onReadyClickHandler.bind(this);
    }

    componentDidMount() {
        this.autoReady();
    }

    setAutoReadyTimeout(playerState: PlayerState, gameState: GameState): number {
        if (playerState === PlayerState.NotReady && gameState === GameState.Play) {
            return this.playTurnTimeoutSeconds;
        } else if (gameState === GameState.Pause) {
            return this.roundTimeoutSeconds;
        }
        return -1
    }

    setDisplayText(): string {
        let readyText = localizedString.readyUp;
        if (this.props.gameState === GameState.Open && this.props.readyState === PlayerState.Ready) {
            readyText = localizedString.unReady;
        }

        if (this.props.readyState === PlayerState.NotReady && this.props.gameState === GameState.Play) {
            readyText = localizedString.beginPlayTurn;
        }

        if (this.props.gameState === GameState.Pause) {
            readyText = localizedString.beginRound;
        }
        return readyText;
    }

    setDisplayStyle(): string {
        let readyStyle = "readyPos";
        if (this.props.gameState !== GameState.Open) {
            readyStyle = "continuePos";
        }

        return readyStyle
    }

    autoReady() {
        if (this.props.readyState === PlayerState.NotReady && this.props.gameState !== GameState.Open) {
            this.autoReadyTimer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        let seconds = this.state.timeRemaining - 1;
        this.setState({ timeRemaining: seconds });
        if (seconds === 0) {
            clearInterval(this.autoReadyTimer);
            this.props.onReadyClick();
        }
    }

    onReadyClickHandler() {
        if (this.state.timeRemaining > 1 || this.state.timeRemaining === -1) {
            this.props.onReadyClick();
        }
    }

    render(): JSX.Element {
        return (
            <div className={"btn-ready-container "  + this.setDisplayStyle()}>
                <button type="button" className="btn-ready" onClick={this.onReadyClickHandler} data-testid="playerReadyButton">
                    {this.setDisplayText()}
                    {this.props.gameState !== GameState.Open &&
                        <div className="btn-ready-countdown">({this.state.timeRemaining})</div>
                    }
                </button>
            </div>
        );
    }

};
