import io from "socket.io-client";
import { GameClient, Game, Card, CurrentPlayer, GameChat, GameListing } from "common";
const GAME_SERVER_ENDPOINT = process.env.REACT_APP_GAME_SERVER || 'http://localhost:3001';

export class GameService {

    private client: GameClient = new GameClient(io(GAME_SERVER_ENDPOINT));

    public createGame(name: string) : void{
        this.client.createGame(name);
    }
    
    public joinGame(gameGuid: string, name: string) : void {
        this.client.joinGame(gameGuid, name);
    }

    public updateGame(callback: (game: Game)=>  void) : void {
        this.client.onUpdate(callback);
    }

    public setName(name: string) : void{
        this.client.nameClient(name);
    }

    public sendMsg( msg: string) : void{
        this.client.chat(msg);
    }

    public togglePlayerReady() : void{
        this.client.toggleReady();
    }

    public getMessages(callback: (msg: GameChat) => void) : void {
        this.client.onMessage(callback);
    }

    public getPlayer(callback: (player : CurrentPlayer) => void): void {
        this.client.onUpdatePlayer(callback);
    }

    public getGameList(callback: (gameListings: GameListing[]) => void): void {
        this.client.onUpdateGameList(callback);
    }

    public submitBid(bid: number): void {
        this.client.submitBid(bid);
    }

    public submitCard(card: Card): void {
        this.client.submitCard(card);
    }
}
