import LocalizedStrings from 'react-localization';

export const localizedString = new LocalizedStrings({
 en:{
   welcome:"Welcome to Wozard Online!",
   welcomeLogLine:"The ultimate card game, now online!",
   createGame:"Create game",
   joinGame:"Join a game",
   play: "Play",
   players: "Players",
   round: "Round",
   playerNamePlaceHolder:"Player Name",
   gameIdPlaceHolder: "Game ID",
   readyUp:"Ready",
   unReady : "Unready",
   beginPlayTurn: "Next Turn...",
   beginRound: "Next Round...",
   guess:"Guess",
   cantGuess: "Cant guess",
   send:"SEND",
   chatPlaceHolder:"Say something..",
   stateHeader:"State",
   dealerHeader:"Dealer",
   emptySlot:"Free Slot",
   languageChange:"Change language to",
   currentBid: "Guessed",
   bidOneLetterAbbrev: "B",
   tricksOneLetterAbbrev: "T",
   tricksWon: "Turns Won",
   score: "Score",
   disconnect: "Leave Game",
   optionsMenuTitle: "Game Options",
   open: "",
   notReady: "Waiting to be ready...",
   ready: "Ready!",
   queuedForDeal: "Queued for deal",
   dealt: "Dealt",
   queuedForBid: "Waiting to Guess...",
   bidding: "Guessing...",
   bid: "Guessed!",
   queuedForPlay: "Waiting to Play...",
   playing: "Choosing a card...",
   played: "Played!",
   won: "Winner!!!",
   lost: "Lost :(",
 },
 de: {
   welcome:"Willkommen bei Wozard Online",
   welcomeLogLine:"Das ultimative Kartenspiel, jetzt online",
   createGame:"Spiel erstellen",
   joinGame:"Spiel beitreten",
   play: "Spiel",
   players: "Spieler",
   round: "Spielrunde",
   playerNamePlaceHolder:"Spieler Name",
   gameIdPlaceHolder: "Spiel ID",
   readyUp:"Bereit",
   unReady : "Unready",
   beginPlayTurn: "Next Turn...",
   beginRound: "Next Round...",
   guess:"vermuten",
   cantGuess: "Cant guess",
   send:"SEND",
   chatPlaceHolder:"Sag etwas..",
   stateHeader:"Zustand",
   dealerHeader:"der Geber",
   emptySlot:"Leerer Schlitz",
   languageChange:"Sprache ändern in",
   currentBid: "Current Guess",
   bidOneLetterAbbrev: "V",
   tricksOneLetterAbbrev: "S",
   tricksWon: "Tricks gewonnen won",
   score: "Punkte",
   disconnect: "Speil verlassen",
   optionsMenuTitle: "Spieleinstellungen",
   Open: "Open",
   NotReady: "Waiting to be ready...",
   Ready: "Ready!",
   QueuedForDeal: "Queued for deal",
   Dealt: "Dealt",
   QueuedForBid: "Waiting to Guess...",
   Bidding: "Guessing...",
   Bid: "Guessed!",
   QueuedForPlay: "Waiting to Play...",
   Playing: "Choosing a card...",
   Played: "Played!",
   Won: "Winner!!!",
   Lost: "Lost :(",
 }
});