import React from "react";
import "./player.css";
import { PlayerDetails } from "./playerdetails";
import { Player as CommonPlayer } from "common";
import { GameService } from "../services/game.service";
import { localizedString } from "../localizedstrings";

interface PlayerProps extends CommonPlayer {
    self: boolean;
    displayDetails: boolean;
    gameService: GameService;
    trickWinner: boolean;
}

export class Player extends React.Component<PlayerProps> {

    hide() {
        if (this.props.guid === "" && this.props.name === localizedString.getString('emptySlot')) {
            return "hidden";
        } else {
            return ""
        }
    }

    trickWinnerDisplay(): string {
        if (this.props.trickWinner) {
            return "trickWinner"
        } else {
            return ""
        }
    }
    render(): JSX.Element {
        return (
            <div className={`${this.props.state} ${this.trickWinnerDisplay()}`}>
                <div>
                    <div className="player">
                        {this.props.displayDetails && 
                            <PlayerDetails guid={this.props.guid} name={this.props.name} slot={this.props.slot} state={this.props.state} isDealer={this.props.isDealer} self={this.props.self} gameService={this.props.gameService}></PlayerDetails>
                        }
                    </div>
                </div>
            </div>
        );
    };
}