import React from "react";
import "./gamelisting.css";
import { localizedString } from "../localizedstrings";
import { GameService } from "../services/game.service";

import { GameListing as CommonGameListing, GameState } from "common";

interface GameListingProps {
    gameService: GameService;
}

interface GameListingState {
    gameListings: CommonGameListing[];
}

export class GameListing extends React.Component<GameListingProps, GameListingState> {

    constructor(props: GameListingProps) {
        super(props);

        this.state = {
            gameListings: [],
        }
    }

    componentDidMount(): void {
        this.props.gameService.getGameList((gameListings: CommonGameListing[]): void => {
            this.setState({gameListings: gameListings});
        });
    }

    render() {
        return (
            <div>
                <div className="gameslist-table">
                    {this.state.gameListings.map((val) => {
                        return (
                            <div className="gamelist-row">
                                <div>{localizedString.players}: {val.connectedPlayers}/6</div>
                                <div>{localizedString.round}: {val.rounds}</div>
                                <div>{val.state}</div>
                                <div>
                                    {val.state === GameState.Open ? (
                                        <a href={"/?gameId=" + val.guid} className="btn-play">{localizedString.play}</a>
                                    ) : (
                                        <div className="menu-spacer"></div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}
