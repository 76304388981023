import React from "react";
import "./game.css";
import { Board } from "./board";
import { PlayerControls } from "./playercontrols";
import { GameService } from "../services/game.service";
import { Game, GameState, CurrentPlayer, PlayerState } from "common";
import { Scoreboard } from "./scoreboard/scoreboard";
import { MainMenu } from "./mainmenu";
import { OptionsMenu } from "./optionsMenu/optionsmenu";
import { OptionsToggle } from "./optionsButtons/optionstogglebutton";
import { localizedString } from "../localizedstrings";
import { ChatBox } from "./chatbox";

export const WozContext = React.createContext({
    game: {} as Game,
    currentPlayer: {} as CurrentPlayer
})

interface ClientGameProps { }

interface ClientGameState {
    game: Game;
    currentPlayer: CurrentPlayer;
    scoreboardVisible: boolean;
    toggleScoreBoard: any;
}

const OpenScoreboardButton = ({ onOpen }: { onOpen: () => void }) => (
    <button className="btn-scoreboardToggle" type="button" onClick={onOpen}>
        <img alt="scoreboard toggle button" src="https://wozard-assets.vereto.net/score.png" />
    </button>
)

export class ClientGame extends React.Component<ClientGameProps, ClientGameState> {

    gameService = new GameService();
    constructor(props: ClientGameProps) {
        super(props);
        this.state = {
            game: {
                guid: "",
                connectedPlayers: [],
                state: "open" as GameState,
                rounds: [],
            },
            currentPlayer: {
                guid: "",
                name: "",
                slot: 0,
                state: "notReady" as PlayerState,
                isDealer: false,
                hand: { cards: [] }
            },
            scoreboardVisible: false,
            toggleScoreBoard: this.toggleScoreBoard
        }
    }

    componentDidMount(): void {
        this.gameService.updateGame((game: Game): void => {
            this.setGameUrl(game);
            this.setState({ game: game });
            console.log(JSON.stringify(this.state.game));
        });
        this.gameService.getPlayer((player: CurrentPlayer): void => {
            this.setState({ currentPlayer: player });
        });
    }

    toggleScoreBoard = () => {
        this.setState({
            scoreboardVisible: !this.state.scoreboardVisible,
        });
    };

    setGameUrl(game: Game) {
        const gameUrl = window.location.origin + window.location.pathname + "?gameId=" + game.guid;
        window.history.pushState({ path: gameUrl }, "", gameUrl);
    }

    render(): JSX.Element {
        return (
            <>
                <WozContext.Provider value={this.state}>
                    <OptionsMenu gameService={this.gameService} connectedPlayersNum={this.state.game.connectedPlayers.length}></OptionsMenu>
                    <OptionsToggle></OptionsToggle>
                    {this.state.game.rounds.length > 0 &&
                        <div className="round-counter">{localizedString.round}: {this.state.game.rounds.length}/{60 / this.state.game.connectedPlayers.length}</div>
                        
                    }
                    {this.state.game.connectedPlayers.length === 0 &&
                        <MainMenu gameService={this.gameService}></MainMenu>
                    }
                    {this.state.scoreboardVisible || this.state.game.state === GameState.Pause || this.state.game.gameWinner !== undefined
                        ? <Scoreboard onCloseBtnClick={() => this.toggleScoreBoard()} renderCloseBtn={this.state.game.state !== GameState.Pause}></Scoreboard>
                        : <OpenScoreboardButton onOpen={() => this.toggleScoreBoard()} />
                    }
                    <div className="game-winner"><h1>{this.state.game.state}</h1></div>
                    {this.state.game.gameWinner !== undefined &&
                        <div className="game-winner"><h1>🎉 {this.state.game.gameWinner.name} has won the game! 🎉</h1></div>
                    }
                    <Board game={this.state.game} currentPlayer={this.state.currentPlayer} gameService={this.gameService}></Board>
                    <div className="gameControls">
                        <PlayerControls gameService={this.gameService}></PlayerControls>
                    </div>
                    {this.state.game.connectedPlayers.length > 0 && 
                        <ChatBox gameService={this.gameService}></ChatBox>
                    }
                </WozContext.Provider>
            </>
        );
    };
}
