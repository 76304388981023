import React from "react";
import "./chatinput.css";
import { GameService } from "../services/game.service";
import { localizedString } from "../localizedstrings";

interface ChatInputProps {
    gameService: GameService;
}

interface ChatInputState {
    messageToSend: string;
}

export class ChatInput extends React.Component<ChatInputProps, ChatInputState> {
    
    constructor(props: ChatInputProps) {
        super(props);

        this.state = {
            messageToSend: "",
        }
        this.updateMessageToSend = this.updateMessageToSend.bind(this);
    }
    
    componentDidUpdate(): void {
        this.scrollToBottom();
    }

    scrollToBottom = () => { }

    updateMessageToSend(event: any) {
        this.setState({ messageToSend: event.target.value})
    }

    sendMessage = (): void => {
        if (this.state.messageToSend !== "") {
            this.props.gameService.sendMsg(this.state.messageToSend);
            this.setState({ messageToSend: "" });
        }
    }

    handleEnterKey = (e: any): void => {
        if (e.key === 'Enter') {
            this.sendMessage();
        }
    }

    render(): JSX.Element {
        return (
            <div className="chat-input-container">
                <div className="chat-input-wrapper">
                    <div className="msgInput">
                        <input placeholder={localizedString.chatPlaceHolder + ".."} value={this.state.messageToSend} className="msgInputBox" onChange={this.updateMessageToSend} onKeyPress={e => {this.handleEnterKey(e)}}/>
                    </div>
                </div>
            </div>
        );
    };
}