import React from "react";
import "./playercontrols.css";
import { Hand } from "./hand";
import { BidInput } from "./bidinput";
import { PlayerDetails } from "./playerdetails";
import { TrumpCard } from "./trumpcard";
import { PlayerState, CardSuit, Round } from "common";
import { GameService } from "../services/game.service";
import { WozContext } from "./game";

interface PlayerControlsProps {
    gameService: GameService;
}

interface PlayerControlsState { }

// The Player controls window should contain
export class PlayerControls extends React.Component<PlayerControlsProps, PlayerControlsState> {

    getLeadingCardSuit(rounds: Round[]): CardSuit | undefined {
        let leadingCardSuit = undefined;
        if (rounds.length > 0) {
            const recentRound = rounds[rounds.length - 1]
            if (recentRound.turns.length > 0) {
                const recentTurn = recentRound.turns[recentRound.turns.length - 1]
                leadingCardSuit = recentTurn.leadingSuit;
            }
        }
        return leadingCardSuit;
    }

    render(): JSX.Element {
        return (
            <WozContext.Consumer>
                {({ game, currentPlayer }) => (
                    <div className="playerControls-wrapper"> 
                        <div className={`playerControls ${currentPlayer.state}-current-player`}>
                            <PlayerDetails guid={currentPlayer.guid} name={currentPlayer.name} slot={currentPlayer.slot} state={currentPlayer.state} isDealer={currentPlayer.isDealer} self={true} gameService={this.props.gameService}></PlayerDetails>
                            
                            <TrumpCard game={game}></TrumpCard>
                            {currentPlayer.state === PlayerState.Bidding &&
                                <BidInput gameService={this.props.gameService} roundNumber={game.rounds.length} disallowedBid={game.rounds[game.rounds.length - 1].disallowedBid}></BidInput>
                            }
                            <Hand gameService={this.props.gameService} hand={currentPlayer.hand} leadingCardSuit={this.getLeadingCardSuit(game.rounds)}></Hand>
                        </div>
                    </div>
                )}
            </WozContext.Consumer>
        );
    };
}