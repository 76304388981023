import React from "react";
import "./disconnectbutton.css";
import { localizedString } from "../../localizedstrings";
import { GameService } from "../../services/game.service";

interface DisconnectButtonProps {
    gameService: GameService;
}

export class DisconnectButton extends React.Component<DisconnectButtonProps> {
    disconnect() {
        const gameUrl = window.location.origin
        window.history.pushState({ path: gameUrl }, "", gameUrl);
        window.location.reload(false);
    }

    render(): JSX.Element {
        return (
            <div className="btn-disconnect-wrapper">
                <button className="btn-option btn-leave" type="button" onClick={() => this.disconnect()}>
                    {localizedString.disconnect}
                </button>
            </div>
        );
    }
}
