import React from "react";
import "./board.css";
import { Pile } from "./pile";
import { Player } from "./player";
import { PlayerState, Game, CurrentPlayer, Player as CommonPlayer, GameState } from "common";
import { GameService } from "../services/game.service";
import { localizedString } from "../localizedstrings";

interface BoardProps {
    game: Game;
    currentPlayer: CurrentPlayer;
    gameService: GameService;
}

interface BoardState {
    players: any;
}

// The game board
export class Board extends React.Component<BoardProps, BoardState> {

    constructor(props: BoardProps) {
        super(props);

        this.state = {
            players: []
        }
    }

    findPlayerPos(game: Game, currentPlayer: CurrentPlayer): number {
        let playerPos = 0
        game.connectedPlayers.forEach((player, index) => {
            if (player.guid === currentPlayer.guid) {
                playerPos = index;
            }
        });
        return playerPos;
    }

    fillPlayerSlots(game: Game, currentPlayer: CurrentPlayer) {
        let players = [];
        let playerPos = this.findPlayerPos(game, currentPlayer);
        let limit = 6

        // Place opposing players to the left
        for (let i = playerPos; i < limit; i++) {
            if (game.connectedPlayers[i]) {
                if (game.connectedPlayers[i].guid !== currentPlayer.guid) {
                    players.push(
                        <Player guid={game.connectedPlayers[i].guid}
                            name={game.connectedPlayers[i].name}
                            slot={game.connectedPlayers[i].slot}
                            state={game.connectedPlayers[i].state}
                            isDealer={game.connectedPlayers[i].isDealer}
                            self={false}
                            gameService={this.props.gameService}
                            key={game.connectedPlayers[i].guid}
                            displayDetails={true}
                            trickWinner={this.isTrickWinner(game.connectedPlayers[i])}></Player>
                    )
                } else {
                    players.push(
                        <Player guid={game.connectedPlayers[i].guid}
                            name={game.connectedPlayers[i].name}
                            slot={game.connectedPlayers[i].slot}
                            state={game.connectedPlayers[i].state}
                            isDealer={game.connectedPlayers[i].isDealer}
                            self={false}
                            gameService={this.props.gameService}
                            key={game.connectedPlayers[i].guid}
                            displayDetails={false}
                            trickWinner={this.isTrickWinner(game.connectedPlayers[i])}></Player>
                    )
                }
               
            } else {
                // Display empty player slot if game is in open state
                if (game.state !== GameState.Open) {
                    players.push(
                        <Player guid="" 
                            name={localizedString.getString('emptySlot')}
                            slot={0}
                            state={PlayerState.Open}
                            isDealer={false} 
                            self={false} 
                            gameService={this.props.gameService} 
                            key={"empty" + i} 
                            displayDetails={false} 
                            trickWinner={false}></Player>
                    )
                } else {
                    players.push(
                        <Player guid="" 
                            name={localizedString.getString('emptySlot')}
                            slot={0}
                            state={PlayerState.Open} 
                            isDealer={false} 
                            self={false} 
                            gameService={this.props.gameService} 
                            key={"empty" + i} 
                            displayDetails={true} 
                            trickWinner={false}></Player>
                    )
                }
                
            }
        }

        // Place opposing players to the right (if there are any)
        for (let i = 0; i < playerPos; i++) {
            if (game.connectedPlayers[i]) {
                players.push(
                    <Player guid={game.connectedPlayers[i].guid}
                        name={game.connectedPlayers[i].name}
                        slot={game.connectedPlayers[i].slot}
                        state={game.connectedPlayers[i].state}
                        isDealer={game.connectedPlayers[i].isDealer}
                        self={false}
                        gameService={this.props.gameService}
                        key={game.connectedPlayers[i].guid}
                        displayDetails={true}
                        trickWinner={this.isTrickWinner(game.connectedPlayers[i])}></Player>
                )
            } else {
                // Display empty player slot if game is in open state
                if (game.state !== GameState.Open) {
                    players.push(
                        <Player guid="" 
                            name={localizedString.getString('emptySlot')}
                            slot={game.connectedPlayers[i].slot}
                            state={PlayerState.Open} 
                            isDealer={false} 
                            self={false} 
                            gameService={this.props.gameService} 
                            key={"empty" + i} 
                            displayDetails={false} 
                            trickWinner={false}></Player>
                    )
                } else {
                    players.push(
                        <Player guid="" 
                            name={localizedString.getString('emptySlot')}
                            slot={game.connectedPlayers[i].slot}
                            state={PlayerState.Open} 
                            isDealer={false} 
                            self={false} 
                            gameService={this.props.gameService} 
                            key={"empty" + i} 
                            displayDetails={true} 
                            trickWinner={false}></Player>
                    )
                }
            }
        }
        return players;
    }

    isTrickWinner(player: CommonPlayer) {
        // Notate the trick winner if we can find him 
        if (this.props.game.rounds.length > 0) {
            if (this.props.game.rounds[this.props.game.rounds.length - 1].turns.length > 0) {
                let trickWinner = this.props.game.rounds[this.props.game.rounds.length - 1].turns[this.props.game.rounds[this.props.game.rounds.length - 1].turns.length - 1].trickWinner
                if (trickWinner?.guid === player.guid) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    render(): JSX.Element {
        return (
            <div className="game-board">
                <Pile game={this.props.game}></Pile>
                <div className="t">
                    {this.fillPlayerSlots(this.props.game, this.props.currentPlayer)}
                </div>
            </div>
        );
    };
}