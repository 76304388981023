import React from "react";
import "./trumpcard.css";
import { ClientCard } from "./card";
import { CardSuit, CardValue, Game } from "common";

interface TrumpCardProps {
    game: Game
}

export class TrumpCard extends React.Component<TrumpCardProps> {
    getRoundTrumpCard(game: Game) {
        if (game.rounds.length > 0 && game.rounds.length < (60 / game.connectedPlayers.length)) {
            const currentRound = game.rounds[game.rounds.length - 1];
            return <div><ClientCard id={currentRound.trump?.id || 0} value={currentRound.trump?.value as CardValue} suit={currentRound.trump?.suit as CardSuit}></ClientCard></div>
        }
    }

    render() {
        return (
            <div className="trump-card">
                <p>Trump Card</p>
                <div className="trump-card-inner">
                    {this.getRoundTrumpCard(this.props.game)}
                </div>
            </div>
        );
    };
}