import React from "react";
import "./bidinput.css";
import { GameService } from "../services/game.service";
import { localizedString } from "../localizedstrings";

interface BidInputProps {
    gameService: GameService;
    roundNumber: number;
    disallowedBid: number;
}

interface BidInputState {
    bidToSend: number;
}

// Input box with accept button to submit how 
// many tricks a player will get
export class BidInput extends React.Component<BidInputProps, BidInputState> {

    constructor(props: BidInputProps) {
        super(props);

        this.state = {
            bidToSend: this.initBidToSend(),
        }
        this.updateBidToSend = this.updateBidToSend.bind(this);
        this.incrementBid = this.incrementBid.bind(this);
        this.decrementBid = this.decrementBid.bind(this);
        this.scrollcrementBid = this.scrollcrementBid.bind(this);
    }

    initBidToSend(): number {
        console.log(this.props.disallowedBid);
        return this.props.disallowedBid === 0 ? 1 : 0;
    }

    updateBidToSend(event: any) {
        this.setState({ bidToSend: Number(event.target.value)});
    }

    sendBid = (): void => {
        this.props.gameService.submitBid(this.state.bidToSend);
        this.setState({ bidToSend: this.initBidToSend()});
    }

    incrementBid() {
        let nextBid = this.state.bidToSend;
        nextBid = nextBid + 1;

        if (nextBid === this.props.disallowedBid) {
            nextBid = nextBid + 1;
        }

        if (nextBid <= this.props.roundNumber) {
            this.setState({ bidToSend: nextBid});    
        }
    }

    decrementBid() {
        let nextBid = this.state.bidToSend;
        nextBid = nextBid - 1;

        if (nextBid === this.props.disallowedBid) {
            nextBid = nextBid - 1;
        }

        if (nextBid >= 0) {
            this.setState({ bidToSend: nextBid});    
        }
    }

    scrollcrementBid(event: any) {
        if (event.deltaY < 0) {
            this.incrementBid();
        } else {
            this.decrementBid();
        }
    }

    render(): JSX.Element {
        return (
            <div className="bidInput">
                <div className="bidInputWrapper">
                    <input type="text" className="bidInputBox" readOnly value={this.state.bidToSend} onChange={(this.updateBidToSend)} onWheel={(e) => this.scrollcrementBid(e)} />
                    <button className="btn-up btn-inputbox" onClick={(this.incrementBid)}>↑</button>
                    <button className="btn-down btn-inputbox" onClick={(this.decrementBid)}>↓</button>
                </div>
                <button type="button" className="btn-bid" onClick={this.sendBid}>
                    {localizedString.guess}
                </button>
                {this.props.disallowedBid !== -1 && 
                    <div className="bidCantSend">
                        <p>{localizedString.cantGuess}: {this.props.disallowedBid}</p>
                    </div>
                }
            </div>
        );
    };
}